import React, { useReducer, useState, useEffect } from 'react';
import { Grid, Button, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { addStock } from '../../actions/stock';
import { toast } from 'react-toastify';

const token = localStorage.getItem('token');
const header = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
};
const Stock = (props) => {
    const useStyles = makeStyles((theme) => ({
        button: {
            margin: theme.spacing(1),
            marginTop: '20px',
            width: '200px',
            borderRadius: '0px',
        },
        leftIcon: {
            marginRight: theme.spacing(1),
        },
        rightIcon: {
            marginLeft: theme.spacing(1),
        },
        iconSmall: {
            fontSize: 20,
        },
        root: {
            padding: theme.spacing(3, 2),
        },
        container: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 400,
            marginBottom: '10px',
        },
        formTitle: {
            padding: theme.spacing(1, 2),
            textAlign: 'center',
            background: '#3f51b5',
            color: '#fff',
        },
        submitButtonWrapper: {
            display: 'flex',
            justifyContent: 'center',
        },
        inputGroups: {
            padding: theme.spacing(0, 1),
        },
    }));

    const [title, setTitle] = useState();
    const [quantity, setmobile] = useState();

    const dispatch = useDispatch();
    let history = useHistory();

    const classes = useStyles();

    const handleTitle = (e) => {
        setTitle(e.target.value);
    };
    const handleMobile = (e) => {
        setmobile(e.target.value);
    };
    console.log(quantity,'quantity')
    return (
        <div>
            <Grid item xs={8} style={{ margin: '0 auto' }}>
                <Paper>
                    <Typography variant="h5" component="h3" className={classes.formTitle}>
                        Add Stock
                    </Typography>
                    <Typography component="p">{props.formDescription}</Typography>

                    <Formik
                        initialValues={{
                            title: title,
                            quantity: quantity
                        }}
                        enableReinitialize={true}
                        onSubmit={(values) => {
                            dispatch(addStock(values));
                                setTimeout(() => {
                                    history.push(`/stock-list`);
                                }, 3000);
                        }}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                            <form noValidate onSubmit={handleSubmit} id="json-form" className={classes.root}>
                                <Grid item xs={12} className={classes.inputGroups}>
                                    <div class="mb-2">
                                        <label for="title" class="form-label">
                                            Title
                                        </label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            defaultValue={values.title}
                                            name="title"
                                            aria-describedby="title"
                                            onChange={handleTitle}
                                            onBlur={handleBlur}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} className={classes.inputGroups}>
                                    <div class="mb-2">
                                        <label for="debit" class="form-label">
                                            Quantity
                                        </label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            defaultValue={quantity}
                                            name="debit"
                                            aria-describedby="debit"
                                            onChange={handleMobile}
                                            onBlur={handleBlur}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} className={classes.submitButtonWrapper}>
                                    <Button type="submit" variant="contained" color="primary" className={classes.button}>
                                        Save
                                    </Button>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                </Paper>
            </Grid>
        </div>
    );
};

export default Stock;
