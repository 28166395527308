import { STOCK_LIST, STOCK_DELETE, ADD_STOCK } from '../actions/type';

const INITIAL_STATE = {
    list: [{}],
    response: [{}],
};

const stock = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case STOCK_LIST:
            return { ...state, list: action.payload };
        case STOCK_DELETE:
            return { ...state, response: action.payload };
        case ADD_STOCK:
            return { ...state, response: action.payload };
        default:
            return state;
    }
};
export default stock;
