import { ADD_STOCK, STOCK_LIST, STOCK_DELETE } from './type';
import { APIURL } from './config';
import { toast } from 'react-toastify';
const axios = require('axios');

//Add Installment
export const addStock = (values) => async (dispatch) => {
    const token = localStorage.getItem('token');
    var myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);
    myHeaders.append('Content-Type', 'application/json');

    var raw = JSON.stringify({
        title: values?.title,
        quantity: values?.quantity,
    });
console.log(raw,'raw')
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
    };

    fetch(`https://unusual-cyan-springbok.cyclic.app/api/v1/stock`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
            dispatch({
                type: ADD_STOCK,
                payload: result.data,
            });
            toast.success('Data Addedd Successfully!');
        })
        .catch((error) => console.log('error', error));
};

// Loan List
export const stockList = () => async (dispatch) => {
    const token = localStorage.getItem('token');
    const header = {
        Authorization: `Bearer ${token}`,
    };
    const config = {
        headers: header,
    };
    try {
        const res = await axios.get(`https://unusual-cyan-springbok.cyclic.app/api/v1/stock`, config);
        dispatch({
            type: STOCK_LIST,
            payload: res.data.data,
        });
    } catch (err) {
        const errors = err;
    }
};

//Delete Transition
export const deleteStock = (id) => async (dispatch) => {
    const token = localStorage.getItem('token');
    const header = {
        Authorization: `Bearer ${token}`,
    };

    const config = {
        headers: header,
    };
    try {
        const res = await axios.delete(`https://unusual-cyan-springbok.cyclic.app/api/v1/stock/${id}`, config);
        dispatch({
            type: STOCK_DELETE,
            payload: res.data,
        });
        toast.success('Data Deleted Successfully!');
    } catch (err) {
        console.log(err);
    }
};
